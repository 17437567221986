import { authEmitter } from '@cocoonspace/shared/domains/auth/emitters/auth.emitter'
import { useAuth } from '@cocoonspace/shared/domains/auth/hooks/use-auth.hook'
import { useAuthDialogStore } from '@cocoonspace/shared/domains/auth/stores/use-auth-dialog-store.hook'
import { Button } from '@cocoonspace/ui/components/button'
import { Form } from '@cocoonspace/ui/components/form'
import { FormControlledInput } from '@cocoonspace/ui/components/form-controlled-input'
import { Input } from '@cocoonspace/ui/components/input'
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@cocoonspace/ui/components/popover'
import { Separator } from '@cocoonspace/ui/components/separator'
import { zodResolver } from '@hookform/resolvers/zod'
import { CircleHelpIcon } from 'lucide-react'
import { i18n, Trans, useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { AppleConnectButton, GoogleConnectButton } from './social-auth-list'

type FormData = z.infer<typeof loginSchema>

const loginSchema = z.object({
	email: z
		.string()
		.min(1, { message: i18n?.t('errors:forms.required') })
		.email({ message: i18n?.t('errors:forms.invalidEmail') }),
	password: z.string().min(1, { message: i18n?.t('errors:forms.required') }),
})

const LegacyLoginDisclaimer = ({ onClick }: { onClick: () => void }) => (
	<Popover>
		<PopoverTrigger asChild>
			<Button
				variant='link'
				size='sm'
				type='button'
				className='w-full'
			>
				<div className='flex items-center justify-center gap-x-1.5 text-sm'>
					<CircleHelpIcon size={16} />

					<Trans i18nKey='auth:login.legacy.fb.title' />
				</div>
			</Button>
		</PopoverTrigger>

		<PopoverContent
			side='top'
			className='max-w-[320px] text-sm'
		>
			<Trans
				parent='div'
				i18nKey='auth:login.legacy.fb.body'
				className='text-sm'
				components={{
					url: (
						<Button
							className='text-sm underline-offset-[3px]'
							variant='link'
							size='sm'
							onClick={onClick}
						/>
					),
				}}
			/>
		</PopoverContent>
	</Popover>
)

export const LoginForm = () => {
	const router = useRouter()
	const { t } = useTranslation()
	const { login, authError } = useAuth()

	const closeDialog = useAuthDialogStore((state) => state.closeDialog)
	const setActiveTab = useAuthDialogStore((state) => state.setActiveTab)

	const form = useForm<FormData>({
		resolver: zodResolver(loginSchema),
		values: {
			email: '',
			password: '',
		},
	})

	const onSubmitForm = (data: FormData) =>
		login('password', {
			email: data.email!,
			password: data.password!,
		}).then((res) => {
			if (res?.user) {
				if (typeof router.query.continueUrl === 'string') {
					router.push(router.query.continueUrl)
				}

				authEmitter.emit('login.success')
				closeDialog()
			}
		})

	return (
		<div className='flex min-h-[200px] flex-col gap-3'>
			<form
				noValidate
				className='flex flex-col gap-y-6 px-6'
				onSubmit={form.handleSubmit(onSubmitForm)}
			>
				<Form {...form}>
					{!!authError && (
						<p className='mb-4 text-primary text-sm'>{t(authError)}</p>
					)}

					<div className='flex flex-col'>
						<FormControlledInput
							name='email'
							label={t('auth:login.email')}
							control={form.control}
							render={({ field, fieldState }) => (
								<Input
									{...field}
									type='email'
									className='!shadow-none'
									required
								/>
							)}
							required
						/>

						<FormControlledInput
							name='password'
							label={t('auth:login.password')}
							control={form.control}
							render={({ field, fieldState }) => (
								<Input
									{...field}
									type='password'
									className='!shadow-none'
									required
								/>
							)}
							required
						/>

						<div className='text-right'>
							<Button
								variant='link'
								size='sm'
								type='button'
								onClick={() => setActiveTab('forgottenPass')}
							>
								{t('auth:login.recover')}
							</Button>
						</div>
					</div>

					<div className='flex flex-col gap-y-4'>
						<Button
							type='submit'
							data-testid='login-btn'
							className='w-full'
							disabled={form.formState.isSubmitting}
						>
							{t('auth:login.login')}
						</Button>

						<div className='flex flex-row items-center gap-4 leading-none'>
							<Separator className='flex-1' />

							<div className='text-center'>{t('common:or')}</div>

							<Separator className='flex-1' />
						</div>

						<GoogleConnectButton action='login' />

						<AppleConnectButton action='login' />

						<LegacyLoginDisclaimer
							onClick={() => setActiveTab('forgottenPass')}
						/>
					</div>
				</Form>
			</form>

			<Separator />

			<div className='flex flex-row justify-center gap-2 px-6'>
				{t('auth:login.noAccount')}

				<button
					data-testid='goto-register-view-button'
					type='button'
					className='text-primary'
					onClick={() => setActiveTab('register')}
				>
					{t('auth:login.register')}
				</button>
			</div>
		</div>
	)
}
